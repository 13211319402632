
/**
 * An object, that represents the value of the "fields" search query with all of its attributes and child attributes
 */
export type FieldsValueObject = Omit<FieldAttribute, 'name'>;

interface FieldAttribute {
  /**
   * the name of the field attribute
   */
  name?: string;
  /**
   * whether the field attribute entails to follow the sub attributes of a preconfigured set
   */
  preConfiguredSet?: 'BASIC' | 'DEFAULT' | 'FULL';
  /**
   * A list of specified sub attributes of this field
   * possible to only use a string as the name of the sub attribute
   */
  fields?: (FieldAttribute | string)[];
}

export function buildFieldsValueFromObject(obj: FieldsValueObject): string {
  const recFn = (val: FieldAttribute): string => {
    const params = [];
    if (val.preConfiguredSet) {
      params.push(val.preConfiguredSet);
    }
    if (val.fields?.length) {
      params.push(...val.fields.map(field => (typeof field === 'string' ? field : recFn(field))/**/));
    }
    if (val.name) {
      return val.name + (params.length > 0 ? ('(' + params.join(',') + ')') : '');
    } else {
      return params.join(',');
    }
  };
  return recFn(obj as FieldAttribute);
}


export function getKurzSpecificProductFields(): (FieldAttribute | string)[] {
  return [
    {
      name: 'quality',
      preConfiguredSet: 'FULL'
    },
    'finishingProcesses',
    'industries',
    'colorCode',
    'colorName',
    'surface',
    'productGroup',
    'productDisplayType',
    'technicalSpecification',
    'recyclable',
    'productGalleryImages',
    'thumbnail',
    'renderingData',
    'glossValue',
    'gloss',
    {
      name: 'labColor',
      preConfiguredSet: 'FULL'
    },
    'baseProduct', 'baseProductId'
  ];
}

export function getKurzSpecificCartFields(): (FieldAttribute | string)[] {
  return [
    {
      name: 'user',
      preConfiguredSet: 'FULL'
    },
    'saveTime',
    'savedBy',
    'name',
    'description',
    'globalDeliveryDate',
    'orderReference',
    'guid',
    'completeDelivery',
    'kurzDeliveryCondition',
    'kurzDeliveryConditionsDescription',
    {
      name: 'kurzDiscounts',
      preConfiguredSet: 'FULL'
    },
    {
      name: 'kurzSurcharges',
      preConfiguredSet: 'FULL'
    },
    {
      name: 'kurzUnusedConditions',
      preConfiguredSet: 'FULL'
    },
    {
      name: 'deliveryAddress',
      preConfiguredSet: 'FULL',
      fields: [
        'companyName'
      ]
    },
    {
      name: 'deliveryMode',
      preConfiguredSet: 'FULL',
    },
    {
      name: 'paymentAddress',
      preConfiguredSet: 'FULL'
    }
  ];
}

export function getKurzSpecificOrderEntryFields(): (FieldAttribute | string)[] {
  return [
    {
      name: 'kurzDiscounts',
      preConfiguredSet: 'FULL'
    },
    {
      name: 'kurzPriceConditions',
      preConfiguredSet: 'FULL'
    },
    {
      name: 'kurzSurcharges',
      preConfiguredSet: 'FULL'
    },
    {
      name: 'priceBaseInfo',
      preConfiguredSet: 'FULL'
    },
    {
      name: 'reorderValidation',
      preConfiguredSet: 'FULL'
    },
    'priceBaseAsOrdered',
    'minimumQuantity',
    'updateable',
    'foilLength',
    'foilWidth',
    'foilArea',
    'myReference',
    'myMaterialNumber',
    'overrideGlobalDate',
    'namedDeliveryDate',
    'noPartialDelivery',
    'finishingType',
    'priceBaseAsOrdered',
    'coreSpecification',
    'masterRoll',
    'guid',
    'stockPosition'
  ];
}
